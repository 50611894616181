import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useState, useEffect } from 'react'
import apiConfig from '../../config/apiConfig';

function TeamMem() {
    const [teamMembers, setTeamMembers] = useState();
    useEffect(async () => {
        const response = await fetch(apiConfig.managementTeamMembersApi);
        const result = await response.json();
        setTeamMembers(result.data);
    }, [])
    
    return (
        <section className="team-member-area pt-115 pb-125">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                    <div className="section-title text-center mb-70">
                        <h2 className="text-dark">MEET <span>MANAGEMENT</span> TEAM</h2>
                    </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {
                        teamMembers && 
                        teamMembers.map((teamMember) => {
                            return (
                                <div className="col-lg-3 col-sm-6" key={teamMember.id}>
                                    <div className="team-member-box text-center mb-50">
                                        <div className="team-member-thumb">
                                        <img src={teamMember.team_member_photo} alt="" />
                                        <div className="team-member-social">
                                            {/* <ul>
                                            <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>
                                            <li><a href="/#"><i className="fab fa-twitter" /></a></li>
                                            <li><a href="/#"><i className="fab fa-pinterest-p" /></a></li>
                                            <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>
                                            </ul> */}
                                        </div>
                                        </div>
                                        <div className="team-member-content">
                                        {/* <h4><Link to="/member-detail/1">Sai Tun Lin</Link></h4> */}
                                        <h4><Link to={"/team/" + teamMember.id + "/detail"}>{teamMember.team_member_name}</Link></h4>
                                        <span>{teamMember.team_member_role}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default TeamMem
import { BrowserRouter as Router, Switch} from 'react-router-dom';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Blogs from './pages/blogs/Blogs';
import BlogDetails from './pages/blogdetails/BlogDetails'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import Team from './pages/team/Team';
import MemberDetail from './pages/memberdetails/MemberDetail';
import Match from './pages/match/Match';
import Partner from './pages/partner/Partner';
import Home from './pages/homes/Home';
import EventAndShop from './pages/event_and_shop/EventAndShop';
import Order from './pages/order/Order';
// import Loader from "react-js-loader";





function App() {
      //  const [loading,setLoading] = useState(false);

      //  useEffect(()=>{
      //   setLoading(true)
      //   setTimeout(()=>{
      //     setLoading(false)
      //   },8000)
      //  },[])
      //  {
      //   loading ?(
      //     <MoonLoader color={color} loading={loading} css={override} size={150} />
      //   )
         
  
      // }
  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
        <ScrollToTopRoute exact={true} path='/'>
          <Home/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/contact'>
        <Contact/>
        </ScrollToTopRoute>
        
        <ScrollToTopRoute exact={true} path='/about-us'>
        <AboutUs/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/team'>
        <Team/>
        </ScrollToTopRoute>
        <ScrollToTopRoute path='/team/:id'>
        <MemberDetail/>
        </ScrollToTopRoute>
        <ScrollToTopRoute path='/match'>
        <Match/>
        </ScrollToTopRoute>
        <ScrollToTopRoute path='/partner'>
        <Partner/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/event_and_shop'>
        <EventAndShop/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/blogs'>
        <Blogs/>
        </ScrollToTopRoute>
        <ScrollToTopRoute path='/blog-detail/:slug'>
        <BlogDetails/>
        </ScrollToTopRoute>
        <ScrollToTopRoute path='/shop/:itemId/order'>
        <Order/>
        </ScrollToTopRoute>
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;
